import styles from './style.module.scss'
import {useMemo} from "react";
import {makeClassName} from "react-appgo-helpers";

export default function Button({
    label,
    onClick,
    href,
    type,
    elementProps,
    children,
}) {
    const classNames = useMemo(() => makeClassName(styles.button, {
        [styles.buttonSecondary]: type === 'secondary',
    }), [type])

    const Content = label ?? children

    if(href) {
        return (
            <a
                href={href}
                className={classNames}
                {...elementProps}
            >
                {Content}
            </a>
        )
    }

    return (
        <button
            className={classNames}
            onClick={onClick}
            {...elementProps}
        >
            {Content}
        </button>
    )
}
