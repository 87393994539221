import styles from './style.module.scss'
import {DndContext} from "@dnd-kit/core";
import {Button, CustomerDetailsForm, ImageMaker} from "../index";
import {useCallback, useRef} from "react";
import {useFormContext} from "react-hook-form";
import appIconImg from "../../assets/img/app_logo.svg";

export default function Step2({image, mutation, onPrevStep, onSubmit}) {
    const {handleSubmit} = useFormContext()

    return (
        <div className={styles.wrapper}>
            <div>
                <img src={image} alt="Konkurs Mionetto" className={styles.imagePreview}/>
            </div>
            <div className={styles.content}>
                <div className={styles.content__top}>
                    <div className="editArea" style={{textAlign: 'center', marginBottom: 16}}>
                        <img src={appIconImg} alt="" className="appIcon" style={{marginBottom: 9}}/>
                        <h3 style={{marginBottom: 10}}>
                            WEJDŹ DO SŁONECZNEGO
                            <br/>ŚWIATA MIONETTO!
                        </h3>
                        <h6 style={{maxWidth: 370, margin: '0 auto'}}>
                            Wypełnij poniższe pola i wyślij zgłoszenie, by mieć szansę na zdobycie głównej nagrody!
                        </h6>
                    </div>
                    <CustomerDetailsForm/>
                </div>

                <div className={styles.content__bottom}>
                    <Button onClick={onPrevStep}>
                        <img src={require('../../assets/img/arrow_full_left.svg').default} alt="" style={{paddingRight: 4}}/>
                        {' '}WSTECZ
                    </Button>
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        elementProps={{disabled: mutation.isLoading}}
                    >
                        {mutation.isLoading ? (
                            <>
                                WYSYŁAM ZGŁOSZENIE
                                {' '}<span className="spinner-border -sm" style={{marginLeft: 4}}/>
                            </>
                        ) : (
                            <>
                                WYŚLIJ ZGŁOSZENIE
                                {' '}<img src={require('../../assets/img/arrow_full_right.svg').default} alt="" style={{paddingLeft: 4}}/>
                            </>
                        )}
                    </Button>
                </div>
                {mutation.isError && (
                    <div className="fieldset__error" style={{textAlign: 'center'}}>Coś poszło nie tak, spróbuj ponownie później.</div>
                )}
            </div>
        </div>
    )
}
