import {Step1, Step2, Step3} from "../index";
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {toJpeg, toPng} from "html-to-image";
import {useCallback, useRef, useState} from "react";
import {useMutation} from "@tanstack/react-query";

const IMAGE_WIDTH = 900
const BASE_URL = window.baseUrl ?? `http://tomek.appgo.local:8000`

export default function Home({moduleEnabled}) {
    const wrapperRef = useRef(null)
    const formMethods = useForm()
    const [step, setStep] = useState(0)
    const [image, setImage] = useState(null)
    const [generatingImage, setGeneratingImage] = useState(false)
    const file = useWatch({
        control: formMethods.control,
        name: 'file',
    })
    const mutation = useMutation({
        mutationFn: async (payload) => {
            return await fetch(`${BASE_URL}/contest/add`, {
                headers: {
                    // 'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-TOKEN': window.csrfToken,
                },
                method: 'post',
                body: payload
            })
                .then(async req => {
                    const response = await req.json()
                    if(req.ok) {
                        return response
                    }
                    throw new Error(response?.message ?? 'Coś poszło nie tak')
                })
        },
        onSuccess: (data) => {
            setStep(idx => idx + 1)
            document.getElementById('root')?.scrollIntoView()
        },
        onError: (error) => {
            console.log(error)
        },
    })

    const onClickGenerate = async () => {
        if (!wrapperRef.current) return

        setGeneratingImage(true)
        const scale = IMAGE_WIDTH * 100 / wrapperRef.current.clientWidth / 100

        wrapperRef.current.style.transform = `scale(${scale})`
        wrapperRef.current.style.transformOrigin = 'top left'

        const generate = () => {
            return toJpeg(wrapperRef.current, {
                width: wrapperRef.current.clientWidth * scale,
                height: wrapperRef.current.clientHeight * scale,
                // cacheBust: true,
                skipAutoScale: true,
            })
        }

        await generate()
            .then(dataUrl => {
                setImage(dataUrl)
                setStep(idx => idx + 1)

                document.getElementById('root')?.scrollIntoView()
            })
            .catch(err => {
                console.error(err)
            })

        setGeneratingImage(false)
    }

    const onSubmitCustomerDetails = useCallback((data) => {
        // const {file, ...rest} = data
        // const payload = {
        //     ...rest,
        //     image,
        //     rule1: data.termsAndConditions ? 1 : 0,
        //     rule2: data.rodo ? 1 : 0,
        //     rule3: data.customAgreement1 ? 1 : 0,
        // }

        const formData = new FormData()
        formData.append('email', data.email)
        formData.append('name', data.name)
        formData.append('file', file)
        formData.append('image', image)
        formData.append('rule1', data.termsAndConditions ? 1 : 0)
        formData.append('rule2', data.rodo ? 1 : 0)
        formData.append('rule3', data.customAgreement1 ? 1 : 0)

        mutation.mutate(formData)
    }, [image])

    const onClickShare = useCallback(() => {
        if(!mutation.data) return

        const url = `${BASE_URL}/zgloszenie/${mutation.data.uuid}`

        const data = {
            title: 'Mionetto Konkurs',
            text: 'Sprawdź moją grafikę konkursową!',
            url,
        }
        navigator.share(data)
    }, [image, mutation.status])

    return (
        <FormProvider {...formMethods}>
            {step === 0 && (
                <Step1
                    file={file}
                    submitLoading={generatingImage}
                    wrapperRef={wrapperRef}
                    onClickGenerate={onClickGenerate}
                    moduleEnabled={moduleEnabled}
                />
            )}
            {step === 1 && (
                <Step2
                    image={image}
                    mutation={mutation}
                    onPrevStep={() => setStep(0)}
                    onSubmit={onSubmitCustomerDetails}
                />
            )}
            {step === 2 && (
                <Step3
                    image={image}
                    onSubmit={onClickShare}
                />
            )}
        </FormProvider>
    )
}
