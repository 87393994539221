import styles from './style.module.scss'
import {useFormContext} from "react-hook-form";

export default function CustomerDetailsForm() {
    const {register, formState: {errors}} = useFormContext()

    return (
        <form className={styles.form}>
            <fieldset className="fieldset">
                <input
                    type="text"
                    placeholder="Imię, nazwisko"
                    className="fieldset__input"
                    {...register('name', {required: 'To pole jest wymagane'})}
                />
                {!!errors.name && (
                    <span className="fieldset__error">{errors.name.message}</span>
                )}
            </fieldset>
            <fieldset className="fieldset">
                <input
                    type="email"
                    placeholder="Adres email"
                    className="fieldset__input"
                    {...register('email', {required: 'To pole jest wymagane'})}
                />
                {!!errors.email && (
                    <span className="fieldset__error">{errors.email.message}</span>
                )}
            </fieldset>
            <div className={styles.agreements}>
                <p className="fieldset__checkboxLabel">
                    Administratorem danych osobowych osób, które wezmą udział w&nbsp;Konkursie, jest Huta 19 im.
                    Joachima
                    Fersengelda sp.&nbsp;z&nbsp;o.o. z&nbsp;siedzibą przy ul.&nbsp;Różanej 39 lok. 19, 02-569 Warszawa,
                    wpisana do
                    rejestru
                    przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy
                    w&nbsp;Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000585654.
                    Więcej
                    informacji o&nbsp;przetwarzaniu danych osobowych uczestników Konkursu znajduje się
                    w&nbsp;regulaminie
                    Konkursu
                </p>
                <fieldset className="fieldset">
                    <input
                        id="termsAndConditions"
                        type="checkbox"
                        className="fieldset__checkbox"
                        {...register('termsAndConditions', {required: 'To pole jest wymagane'})}
                    />
                    <label htmlFor="termsAndConditions" className="fieldset__checkboxLabel">
                        Oświadczam, że zapoznałem się z&nbsp;regulaminem Konkursu „Majówkowy relaks”, w&nbsp;tym
                        z&nbsp;informacją o&nbsp;przetwarzaniu moich danych osobowych i&nbsp;wyrażam zgodę na jego
                        postanowienia
                    </label>
                    {!!errors.termsAndConditions && (
                        <span className="fieldset__error">{errors.termsAndConditions.message}</span>
                    )}
                </fieldset>
                <fieldset className="fieldset">
                    <input
                        id="rodo"
                        type="checkbox"
                        className="fieldset__checkbox"
                        {...register('rodo', {required: 'To pole jest wymagane'})}
                    />
                    <label htmlFor="rodo" className="fieldset__checkboxLabel">
                        Oświadczam, że jestem osobą pełnoletnią, posiadającą pełną zdolność do czynności prawnych
                        oraz&nbsp;mam świadomość, że Konkurs dotyczy treści związanych z&nbsp;napojami alkoholowymi,
                        a&nbsp;także
                        że&nbsp;spełniam inne warunki przewidziane regulaminem Konkursu, w&nbsp;tym wskazane w par. 3
                        ust. 1
                        tego
                        regulaminu.
                    </label>
                    {!!errors.rodo && (
                        <span className="fieldset__error">{errors.rodo.message}</span>
                    )}
                </fieldset>
                <fieldset className="fieldset">
                    <input
                        id="customAgreement1"
                        type="checkbox"
                        className="fieldset__checkbox"
                        {...register('customAgreement1')}
                    />
                    <label htmlFor="customAgreement1" className="fieldset__checkboxLabel">
                        Wyrażam zgodę na przesyłanie przez organizatora Konkursu, Huta 19 im. Joachima Fersengelda
                        sp.&nbsp;z&nbsp;o.o. z&nbsp;siedzibą w&nbsp;Warszawie, informacji handlowych dotyczących
                        fundatora nagród
                        w&nbsp;Konkursie,
                        Henkell Freixenet Polska sp.&nbsp;z&nbsp;o.o. i&nbsp;jego produktów na mój adres poczty
                        elektronicznej
                        podany w&nbsp;toku zgłaszania się do&nbsp;Konkursu.
                    </label>
                    {!!errors.customAgreement1 && (
                        <span className="fieldset__error">{errors.customAgreement1.message}</span>
                    )}
                </fieldset>
            </div>
        </form>
    )
}
