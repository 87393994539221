import styles from './style.module.scss'
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useDndMonitor, useDraggable} from "@dnd-kit/core";
import {CSS} from '@dnd-kit/utilities';
import {Button, FileInputController} from "../index";
import {useFormContext, useWatch} from "react-hook-form";
import szarfaImg from '../../assets/img/szarfa.webp'
import watermarkImg from '../../assets/img/watermark.png'
import {motion, AnimatePresence} from "framer-motion"
import {makeClassName} from "react-appgo-helpers";

const staticImages = [
    require('../../assets/img/formatka/formatka_1-1.webp'),
    require('../../assets/img/formatka/formatka_2-1.webp'),
    require('../../assets/img/formatka/formatka_3-1.webp'),
]

const variants = {
    enter: (direction) => {
        return {
            translateX: direction < 0 ? '100%' : '-100%',
        };
    },
    center: {
        zIndex: 1,
        translateX: '0%',
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            translateX: direction > 0 ? '100%' : '-100%',
        };
    }
};

export default function ImageMaker({wrapperRef, imgRef, imgWrapperRef, rotations, rotationIndex, setRotationIndex, rotation}) {
    const seCustomClassTimeoutRef = useRef(null)
    const formContext = useFormContext()
    const inputRef = useRef(null)
    const [customClass, setCustomClass] = useState(null)
    const [imgWrapperDimensions, setImgWrapperDimensions] = useState(null)
    const [imgDimensions, setImgDimensions] = useState(null)
    const [defaultTransform, setDefaultTransform] = useState({x: 0, y: 0})
    const [[bottomImageIndex, bottomImageDirection], setBottomImageIndex] = useState([0, 0])
    const {setNodeRef, listeners, attributes, transform} = useDraggable({
        id: "TOP_IMAGE",
        data: {
            // transform: defaultTransform,
            // defaultTransform
        }
    });
    const bottomImage = useMemo(() => staticImages[bottomImageIndex], [bottomImageIndex])
    const wrapperTopClassName = useMemo(() => makeClassName(styles.wrapper__top, {
        [customClass]: customClass,
    }), [customClass])
    const file = useWatch({
        control: formContext.control,
        name: 'file',
    })

    const onClickRotate = useCallback(() => {
        setRotationIndex(index => {
            if (index + 1 > rotations.length - 1)
                return 0
            return index + 1
        })
    }, [setRotationIndex])

    const onClickPrev = useCallback(() => {
        setBottomImageIndex(([index]) => {
            if (index - 1 < 0)
                return [staticImages.length - 1, -1]
            return [index - 1, -1]
        })
    }, [setBottomImageIndex])

    const onClickNext = useCallback(() => {
        setBottomImageIndex(([index]) => {
            if (index + 1 > staticImages.length - 1)
                return [0, 1]
            return [index + 1, 1]
        })
    }, [setBottomImageIndex])

    useDndMonitor({
        onDragEnd({delta}) {
            setDefaultTransform(({x, y}) => {
                const value = {
                    x: x + delta.x,
                    y: y + delta.y,
                }

                if(!imgWrapperRef.current || !imgRef.current) {
                    return value
                }

                let animated = false

                if(rotation === 0) {
                    const maxY = imgWrapperRef.current.clientHeight - imgRef.current.clientHeight

                    if(value.y > 0) {
                        value.y = 0
                        animated = true
                    }
                    if(value.y < maxY) {
                        value.y = maxY
                        animated = true
                    }

                } else if(rotation === 90) {
                    const maxY = imgWrapperRef.current.clientHeight - imgRef.current.clientWidth

                    if(value.y > 0) {
                        value.y = 0
                        animated = true
                    }
                    if(value.y < maxY) {
                        value.y = maxY
                        animated = true
                    }

                } else if(rotation === 180) {
                    const minY = imgWrapperRef.current.clientHeight
                    const maxY = imgRef.current.clientHeight

                    if(value.y > maxY) {
                        value.y = maxY
                        animated = true
                    }
                    if(value.y < minY) {
                        value.y = minY
                        animated = true
                    }

                } else if (rotation === 270) {
                    if(value.y > imgRef.current.clientWidth) {
                        value.y = imgRef.current.clientWidth
                        animated = true
                    }
                    if(value.y < imgWrapperRef.current.clientHeight) {
                        value.y = imgWrapperRef.current.clientHeight
                        animated = true
                    }

                }

                if(animated) {
                    clearTimeout(seCustomClassTimeoutRef.current)
                    setCustomClass(styles['wrapper__top-animated'])
                    seCustomClassTimeoutRef.current = setTimeout(() => {
                        setCustomClass(null)
                    }, 200)
                }

                return value
            })
        },
    })

    useEffect(() => {
        if(!imgWrapperRef.current || !imgRef.current) return

        setDefaultTransform(() => {
            const value = {
                x: 0,
                y: 0,
            }

            const containerWidth = imgWrapperRef.current?.clientWidth ?? 0
            const containerHeight = imgWrapperRef.current?.clientHeight ?? 0
            const containerAspectRatio = containerWidth / containerHeight

            const imgWidth = imgRef.current?.clientWidth ?? 0
            const imgHeight = imgRef.current?.clientHeight ?? 0
            const imgAspectRatio = imgWidth / imgHeight

            if(containerAspectRatio < imgAspectRatio) {
                value.x = ((imgWidth - containerWidth) / 2) * -1
            } else {
                value.y = ((imgHeight - containerHeight) / 2) * -1
            }

            if(rotation === 90) {
                value.x = containerWidth
                value.y = (containerHeight - imgWidth) / 2
            } else if(rotation === 180) {
                value.x = containerWidth
                value.y = containerHeight + (imgHeight - containerHeight) / 2
            } else if(rotation === 270) {
                value.y = (containerHeight + imgWidth) / 2
            }

            return value
        })
    }, [rotation, file, JSON.stringify(imgDimensions), JSON.stringify(imgWrapperDimensions)])

    const wrapperRefCallback = useCallback((node) => {
        imgWrapperRef.current = node

        if(!node) return
        setImgWrapperDimensions({
            width: node.clientWidth,
            height: node.clientHeight,
        })
    }, [file])

    const imgRefCallback = useCallback((node) => {
        setNodeRef(node)
        imgRef.current = node

        if(!node) return
        setImgDimensions({
            width: node.clientWidth,
            height: node.clientHeight,
        })
    }, [file])

    const onClickImg = useCallback(() => {
        if(!inputRef.current) return
        inputRef.current.click()
    }, [inputRef.current])

    const imgStyle = {
        top: defaultTransform.y,
        left: defaultTransform.x,
        transformOrigin: 'top left',
    }

    const containerWidth = imgWrapperRef.current?.clientWidth ?? 0
    const containerHeight = imgWrapperRef.current?.clientHeight ?? 0
    const containerAspectRatio = containerWidth / containerHeight

    const imgWidth = imgRef.current?.clientWidth ?? 0
    const imgHeight = imgRef.current?.clientHeight ?? 0
    const imgAspectRatio = imgWidth / imgHeight

    if(containerAspectRatio < imgAspectRatio) {
        imgStyle.height = `${containerHeight}px`
    } else {
        imgStyle.width = `${containerWidth}px`
    }

    if(rotation === 90) {
        imgStyle.height = `${containerWidth}px`
        imgStyle.width = 'auto'
    } else if(rotation === 180) {
        imgStyle.width = `${containerWidth}px`
        imgStyle.height = 'auto'
    } else if(rotation === 270) {
        imgStyle.height = `${containerWidth}px`
        imgStyle.width = 'auto'
    }

    imgStyle.transform = `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0) rotate(${rotation}deg)`

    const wrapperTop = (
        <div
            ref={wrapperRefCallback}
            className={wrapperTopClassName}
        >
            {!!file && (
                <img
                    ref={imgRefCallback}
                    src={URL.createObjectURL(file)}
                    alt=""
                    className={styles.wrapper__topImg}
                    style={{
                        ...imgStyle,
                    }}
                />
            )}
        </div>
    )

    const wrapperBottom = (
        <>
            <div className={styles.wrapper__ribbon}>
                <img src={szarfaImg} alt="Majowy relaks. Prawdziwie wyjątkowy"/>
            </div>
            <div className={styles.wrapper__bottom}>
                <AnimatePresence initial={false} custom={bottomImageDirection}>
                    <motion.img
                        key={bottomImageIndex}
                        src={bottomImage}
                        custom={bottomImageDirection}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        // initial={{ translateX: '-100%', opacity: 0 }}
                        // animate={{ translateX: '0%', opacity: 1 }}
                        // exit={{ translateX: '100%', opacity: 0 }}
                        transition={{
                            bounce: 0,
                            // duration: 20,
                            // translateX: { type: "spring", stiffness: 300, damping: 25 },
                            // opacity: { duration: 0.2 }
                        }}
                    />
                </AnimatePresence>
            </div>
            <div className={styles.wrapper__decoration}>
                <img src={watermarkImg} alt=""/>
            </div>
        </>
    )

    return (
        <div className={styles.container}>
            <div className={styles.container__content}>
                <div
                    style={{
                        position: 'absolute',
                        inset: 0,
                    }}
                >
                    <div ref={wrapperRef} className={styles.wrapper}>
                        {wrapperTop}
                        {wrapperBottom}
                    </div>
                </div>
                <div className={styles.wrapper}>
                    {wrapperTop}
                    {file ? (
                        <div
                            className={styles.wrapper__topHandle}
                            {...listeners}
                            {...attributes}
                        />
                    ) : (
                        <div
                            className={styles.wrapper__topHandle}
                            onClick={onClickImg}
                            style={{cursor: 'pointer'}}
                        />
                    )}
                    {wrapperBottom}
                </div>
            </div>


            {/*<div className={styles.parentWrapper}>*/}
            {/*    <div*/}
            {/*        ref={wrapperRef}*/}
            {/*        className={styles.wrapper}*/}
            {/*        style={{*/}
            {/*            position: 'absolute',*/}
            {/*            width: '470px',*/}
            {/*            inset: 0,*/}
            {/*            border: 'unset',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <div className={styles.top}>*/}
            {/*            <div*/}
            {/*                ref={setNodeRef}*/}
            {/*                style={{*/}
            {/*                    position: 'absolute',*/}
            {/*                    // top: defaultTransform ? `${defaultTransform.y}px` : undefined,*/}
            {/*                    // left: defaultTransform ? `${defaultTransform.x}px` : undefined,*/}
            {/*                    transform: CSS.Translate.toString(transform),*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <img*/}
            {/*                    src={file ? URL.createObjectURL(file) : require('../../assets/img/top_placeholder.jpg')}*/}
            {/*                    alt=""*/}
            {/*                    className={styles.imgTop}*/}
            {/*                    style={{*/}
            {/*                        transform: `rotate(${rotation}deg) scale(2)`,*/}
            {/*                        // transform: CSS.Translate.toString(transform),*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={styles.bottom}>*/}
            {/*            <img src={bottomImage} alt="" className={styles.imgBottom}/>*/}
            {/*        </div>*/}

            {/*        /!*<div className={styles.bar}>*!/*/}
            {/*        /!*    Majówkowy relaks.PRAWDZIWIE WYJĄTKOWY*!/*/}
            {/*        /!*</div>*!/*/}

            {/*        <img src={require('../../assets/img/szarfa.png')} alt="" className={styles.barImg}/>*/}
            {/*        <img src={require('../../assets/img/watermark.png')} alt="" className={styles.watermarkImg}/>*/}
            {/*    </div>*/}
            {/*    <div className={styles.wrapper}>*/}
            {/*        <div className={styles.top}>*/}
            {/*            <div*/}
            {/*                ref={setNodeRef}*/}
            {/*                style={{*/}
            {/*                    position: 'relative',*/}
            {/*                    top: defaultTransform.y,*/}
            {/*                    left: defaultTransform.x,*/}
            {/*                    width: `70%`,*/}
            {/*                    maxHeight: '70%',*/}
            {/*                    transform: `translate3d(${transform?.x ?? 0}px, ${transform?.y ?? 0}px, 0)`,*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <img*/}
            {/*                    src={file ? URL.createObjectURL(file) : require('../../assets/img/top_placeholder.jpg')}*/}
            {/*                    alt=""*/}
            {/*                    className={styles.imgTop}*/}
            {/*                    style={{*/}
            {/*                        transform: `rotate(${rotation}deg) scale(3)`,*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            {!!file && (*/}
            {/*                <div*/}
            {/*                    className={styles.top__area}*/}
            {/*                    {...listeners}*/}
            {/*                    {...attributes}*/}
            {/*                />*/}
            {/*            )}*/}
            {/*        </div>*/}
            {/*        <div className={styles.bottom}>*/}
            {/*            <img src={bottomImage} alt="" className={styles.imgBottom}/>*/}
            {/*        </div>*/}

            {/*        {!file && (*/}
            {/*            <div className={styles.uploadButton}>*/}
            {/*                <FileInputController/>*/}
            {/*            </div>*/}
            {/*        )}*/}

            {/*        <img src={require('../../assets/img/szarfa.png')} alt="" className={styles.barImg}/>*/}
            {/*        <img src={require('../../assets/img/watermark.png')} alt="" className={styles.watermarkImg}/>*/}
            {/*    </div>*/}
            {/*</div>*/}

                <div className={styles.toolbar}>
                    <div className={styles.toolbar__row}>
                        <FileInputController
                            forwardInputRef={inputRef}
                        />
                        <div className={styles.toolbar__spacer}/>
                        {!!file && (
                            <div className={styles.toolbar__item}>
                                <Button onClick={onClickRotate}>
                                    <img src={require('../../assets/img/rotate_icon.svg').default} alt="Obróć"
                                         style={{height: 18}}/>
                                </Button>
                                <span>Obróć</span>
                            </div>
                        )}
                    </div>
                    <div className={styles.toolbar__row}>
                        <div className={styles.toolbar__item}>
                            <div className={styles.toolbar__row}>
                                <Button onClick={onClickPrev}>
                                    <img src={require('../../assets/img/arrow_left.svg').default} alt="Obróć"
                                         style={{height: 18}}/>
                                </Button>
                                <Button onClick={onClickNext}>
                                    <img src={require('../../assets/img/arrow_right.svg').default} alt="Obróć"
                                         style={{height: 18}}/>
                                </Button>
                            </div>
                            <span>Wybierz zdjęcie</span>
                        </div>
                    </div>
                </div>
        </div>
    )
}


