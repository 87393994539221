import styles from './style.module.scss'
import {DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {Button, ImageMaker} from "../index";
import {restrictToHorizontalAxis, restrictToParentElement, restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {useMemo, useRef, useState} from "react";
import appIconImg from '../../assets/img/app_logo.svg'

const rotations = [0, 90, 180, 270]

export default function Step1({file, wrapperRef, submitLoading, moduleEnabled, onClickGenerate}) {
    const imgWrapperRef = useRef(null)
    const imgRef = useRef(null)
    const [rotationIndex, setRotationIndex] = useState(0)
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor),
    );
    const rotation = useMemo(() => rotations[rotationIndex], [rotationIndex])

    const SubmitButton = () => (
        <Button
            onClick={onClickGenerate}
            elementProps={{disabled: !moduleEnabled || (!file || submitLoading)}}
        >
            {!moduleEnabled ? (
                'Konkurs zakończony'
            ) : submitLoading ? (
                <>
                    GENERUJĘ OBRAZEK
                    {' '}<span className="spinner-border -sm" style={{marginLeft: 4}}/>
                </>
            ) : (
                <>
                    OPUBLIKUJ OBRAZEK
                    {' '}<img src={require('../../assets/img/arrow_full_right.svg').default} alt=""
                              style={{paddingLeft: 4}}/>
                </>
            )}
        </Button>
    )

    const onDragStart = () => {
        document.documentElement.style.overflow = 'hidden'
    }

    const onDragEnd = () => {
        document.documentElement.style.overflow = 'unset'
    }

    function restrictModifier(args) {
        const {transform, containerNodeRect, draggingNodeRect} = args;

        if (!draggingNodeRect || !containerNodeRect) {
            // console.log(args)
            return transform;
        }

        return {
            ...transform,
            x: 0,
        }
    }

    return (
        <>
            <div className="editArea" style={{textAlign: 'center'}}>
                <h2>KONKURS</h2>
                <h6 style={{marginBottom: 26}}>krok po kroku</h6>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.image}>
                    <DndContext
                        sensors={sensors}
                        modifiers={[restrictModifier]}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                    >
                        <ImageMaker
                            wrapperRef={wrapperRef}
                            rotationIndex={rotationIndex}
                            setRotationIndex={setRotationIndex}
                            rotations={rotations}
                            rotation={rotation}
                            imgWrapperRef={imgWrapperRef}
                            imgRef={imgRef}
                        />
                    </DndContext>
                    <div className={styles.submitButtonMobile}>
                        <SubmitButton/>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className="editArea" style={{textAlign: 'center'}}>
                        <img src={appIconImg} alt="" className="appIcon -lg" style={{marginBottom: 32}}/>
                        <ol className="verticalOrderedList" style={{marginBottom: '48px'}}>
                            <li>
                                <b>Zrób zdjęcie</b>, na którym uwiecznisz swoją majową chwilę relaksu (może właśnie ją przeżywasz?)
                            </li>
                            <li>
                                <b>Załaduj</b> je w formatkę Mionetto, tak by uzupełniało jedno z już tam umieszczonych – niech wspólnie
                                opowiedzą Twoją historię wiosennej sielanki.
                            </li>
                            <li>
                                <b>Wypatruj wyników</b> - 50 autorek/autorów najciekawszych pomysłów zostanie nagrodzonych piknikowymi zestawami
                                Mionetto! Zgłoszenia możesz nadsyłać od 26 kwietnia do 15 maja 2023r.
                            </li>
                        </ol>
                        <div className={styles.submitButtonDesktop}>
                            <SubmitButton/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
