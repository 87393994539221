import {Home} from "./components";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import './assets/style/main.scss'

const queryClient = new QueryClient()
export default function App({moduleEnabled}) {
    return (
        <QueryClientProvider client={queryClient}>
            <Home moduleEnabled={moduleEnabled}/>
        </QueryClientProvider>
    );
}
