import styles from './style.module.scss'
import {DndContext} from "@dnd-kit/core";
import {Button, ImageMaker} from "../index";
import {useCallback} from "react";
import appIconImg from "../../assets/img/app_logo.svg";

export default function Step3({image, onSubmit}) {

    const onClickDownload = useCallback(() => {
        const link = document.createElement('a')
        link.download = 'mionetto-konkurs.jpg'
        link.href = image
        link.click()
    }, [image])

    return (
        <div className={styles.wrapper}>
            <div>
                <img src={image} alt="Konkurs Mionetto" className={styles.imagePreview}/>
            </div>
            <div className={styles.content}>
                <div className={styles.content__top}>
                    <div className="editArea" style={{textAlign: 'center'}}>
                        <img src={appIconImg} alt="" className="appIcon" style={{marginBottom: 9}}/>
                        <h2>GOTOWE!</h2>
                        <h5 style={{marginBottom: 24}}>
                            PODZIEL SIĘ ZGŁOSZENIEM
                            <br/>I&nbsp;ODDAJ BŁOGIEMU RELAKSOWI!
                        </h5>
                        {/*<img src={require('../../assets/img/order_finalized.png')} alt="" style={{marginBottom: 20}}/>*/}
                        <h6>
                            Wygląda na to, że bierzesz udział w konkursie Mionetto - wspaniale! Oczekując na wyniki
                            podziel się swoim prawdziwie wyjątkowym zgłoszeniem ze znajomymi. Kto wie, może będziesz dla
                            nich inspiracją do spędzania wiosennego czasu?
                        </h6>
                    </div>
                </div>
                <div>
                    <div className="editArea" style={{textAlign: 'center'}}>
                        <p style={{maxWidth: 435, margin: '0 auto 27px'}}>
                            Twoje zgłoszenie zostało przyjęte. Oczekiwanie na wyniki możesz umilić sobie tak, jak lubisz
                            – <b>prawdziwie wyjątkowo</b>.
                        </p>
                    </div>
                    <div className={styles.content__bottom}>
                        <Button type="secondary" onClick={onClickDownload}>
                            {' '}ZAPISZ OBRAZEK
                            <img src={require('../../assets/img/download_icon.svg').default} alt=""
                                 style={{paddingLeft: 4}}/>
                        </Button>
                        <Button type="secondary" onClick={onSubmit}>
                            UDOSTĘPNIJ
                            {' '}<img src={require('../../assets/img/share_icon.svg').default} alt=""
                                      style={{paddingLeft: 4}}/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
