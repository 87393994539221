import {Button} from "../index";
import {useController, useFormContext, useWatch} from "react-hook-form";
import {useCallback, useMemo, useRef} from "react";

export default function FileInputController({forwardInputRef}) {
    const formMethods = useFormContext()
    const inputRef = useRef(null)
    const {
        field: {ref, value, ...inputProps},
    } = useController({
        control: formMethods.control,
        name: 'file',
        rules: {required: 'Plik jest wymagany!'},
        defaultValue: ''
    })
    const file = useWatch({
        control: formMethods.control,
        name: 'file',
    })
    const filename = useMemo(() => {
        if(!file) return ''
        const filename = file.name.toString().split('\\').pop()
        if(filename.length < 18)
            return filename
        return `${filename.substring(0, 18)}...`
    }, [file])

    const onClick = useCallback(() => {
        if(!inputRef.current) return
        inputRef.current.click()
    }, [inputRef.current])

    const label = filename ? 'ZMIEŃ ZDJĘCIE' : 'ZAŁADUJ ZDJĘCIE'

    return (
        <>
            <Button onClick={onClick}>
                {label} <img src={require('../../assets/img/upload_icon.svg').default} alt="" style={{paddingLeft: 8}}/>
            </Button>
            <input
                ref={r => {
                    ref(r)
                    inputRef.current = r
                    if(forwardInputRef) {
                        forwardInputRef.current = r
                    }
                }}
                {...inputProps}
                onChange={(e) => {
                    if (e.target.files?.length) {
                        const file = e.target.files[0]
                        if(file.size <= 20 * 1024 * 1024) {
                            inputProps.onChange(file)
                        } else {
                            formMethods.setError(
                                'file',
                                {type: 'invalid', message: 'Plik nie może być większy niż 8MB'},
                            )
                        }
                    } else {
                        inputProps.onChange(undefined)
                    }
                }}
                accept="image/jpg,image/jpeg,image/png"
                type="file"
                hidden
            />
        </>
    )
}